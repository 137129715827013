import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  CardProps,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
  },
  avatar: {
    height: 56,
    width: 56,
  },
}));

type IParams = CardProps & {
  title: string;
  countSituations: number;
  descPeriod: string;
  color: string;
  imageInfo: React.ReactNode;
  click: () => void;
};

const CardSituation = ({
  title,
  countSituations,
  descPeriod,
  color,
  imageInfo,
  click,
  ...rest
}: IParams) => {
  const classes = useStyles();

  return (
    <Card
      className={classes.root}
      style={{ backgroundColor: color }}
      onClick={click}
      {...rest}
    >
      <CardContent>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography gutterBottom variant="h6" style={{ color: '#FFFFFF' }}>
              {title}
            </Typography>
            <Typography variant="h3" style={{ color: '#FFFFFF' }}>
              {countSituations}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              className={classes.avatar}
              style={{ backgroundColor: color }}
            >
              {imageInfo}
            </Avatar>
          </Grid>
        </Grid>

        <Box mt={0} display="flex" alignItems="center">
          <Typography variant="caption" style={{ color: '#FFFFFF' }}>
            {descPeriod}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardSituation;
