import React, { useEffect } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { TreeSelect, TreeSelectProps } from 'antd';
import { IconButton, Tooltip } from '@material-ui/core';
import { ArrowDropDown, Announcement, Close } from '@material-ui/icons';
import $ from 'jquery';
import 'antd/dist/antd.css';
import './styles.css';

interface Props {
  errorForm?: boolean;
}

const SelectTreeView: React.FC<
  Props & TreeSelectProps<any> & UseControllerProps<any>
> = ({ errorForm, name, control, rules, defaultValue, ...treeSelectProps }) => {
  useEffect(() => {
    if (errorForm) {
      $('.ant-select-selector').css('border-bottom', '2px solid #f44336');

      $('.ant-select-selector').hover(
        function () {
          $(this).css('border-color', '#f44336');
        },
        function () {
          $(this).css('border-bottom', '2px solid #f44336');
        }
      );
    } else {
      $('.ant-select-selector').css(
        'border-bottom',
        '1px solid rgba(0, 0, 0, 0.42)'
      );

      $('.ant-select-selector').hover(
        function () {
          $(this)
            .css('border-color', 'rgba(0, 0, 0, 0.87)')
            .css('border-width', '2px');
        },
        function () {
          $(this).css('border-bottom', '1px solid rgba(0, 0, 0, 0.42)');
        }
      );
    }
  }, [errorForm]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TreeSelect
          {...field}
          {...treeSelectProps}
          showSearch
          suffixIcon={
            <ArrowDropDown color="action" style={{ marginBottom: 5 }} />
          }
          onChange={field.onChange}
          clearIcon={
            <Tooltip title="Limpar" placement="bottom-start">
              <IconButton size="small">
                <Close style={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          }
          notFoundContent={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Announcement /> Sem opções
            </div>
          }
          placeholder=""
          allowClear
          value={field.value}
          style={{ width: '100%', zIndex: 6 }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 2000 }}
          ref={field.ref}
        />
      )}
    />
  );
};

export default SelectTreeView;
