import { IDepartamento, IDepartamentoTree } from '../models';

const departmentTree = (
  data: IDepartamento[],
  dep: IDepartamento
): IDepartamentoTree => {
  return {
    title: dep.descricao,
    value: dep.id,
    selectable: dep.registrosFilhos === 0,
    children: getChildren(
      data,
      data.filter(x => x.departamentoId === dep.id)
    ),
  } as IDepartamentoTree;
};

const getDeps = (data: IDepartamento[], id: string, child: boolean) => {
  if (child) {
    return getChildren(
      data,
      data.filter(x => x.departamentoId === id)
    );
  }

  return [];
};

const getChildren = (data: IDepartamento[], deps: IDepartamento[]) => {
  const result = deps.map(d => {
    return {
      title: d.descricao,
      value: d.id,
      selectable: d.registrosFilhos === 0,
      children: getDeps(data, d.id, d.registrosFilhos > 0),
    };
  }) as IDepartamentoTree[];

  return result;
};

export default departmentTree;
