import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withTheme } from '@material-ui/core/styles';

export const Container = withTheme(styled(Dialog)`
  .btnAction {
    width: 100;
    margin: theme.spacing(3, 0.5, 2);
  }
`);
