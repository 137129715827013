import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MaterialTable, {
  Action,
  Icons,
  MaterialTableProps,
} from 'material-table';
import {
  AddBox,
  ArrowUpward,
  ArrowForward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  DoneAll,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  Sync,
  ViewColumn,
} from '@material-ui/icons';
import snack from '../SnackbarUtilsConfigurator';
import useUtility from '../../hooks/useUtility';
import { Container } from './styles';

interface Props {
  actionsOverride?: any[];
  onActive?: (data: any) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onView?: (id: string) => void;
  isVerifyRegistrationAutomatict?: boolean;
  isRightSearch?: boolean;
  onRefresh?: () => void;
  hideColumnsButton?: boolean;
  hideIsSearch?: boolean;
  hideExportButton?: boolean;
  hideActionAdd?: boolean;
  hideActionEdit?: boolean;
  hideActionDelete?: boolean;
  showActionFilter?: boolean;
  isfixed?: boolean;
}

const MySort = (props: any) => (
  <span {...props}>
    {' '}
    <ArrowUpward />{' '}
  </span>
);

const MaterialTableX: React.FC<MaterialTableProps<any> & Props> = ({
  actionsOverride,
  onActive,
  onDelete,
  onView,
  isVerifyRegistrationAutomatict,
  isRightSearch,
  onRefresh,
  hideColumnsButton,
  hideIsSearch,
  hideExportButton,
  hideActionAdd,
  hideActionEdit,
  hideActionDelete,
  showActionFilter,
  isfixed,
  ...materialTableProps
}) => {
  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => (
      <AddBox {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Check: forwardRef((props, ref) => (
      <Check {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Clear: forwardRef((props, ref) => (
      <Clear {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Delete: forwardRef((props, ref) => (
      <DeleteOutline {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Edit: forwardRef((props, ref) => (
      <Edit {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Export: forwardRef((props, ref) => (
      <SaveAlt {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Filter: forwardRef((props, ref) => (
      <FilterList {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    FirstPage: forwardRef((props, ref) => (
      <FirstPage {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    LastPage: forwardRef((props, ref) => (
      <LastPage {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    NextPage: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    ResetSearch: forwardRef((props, ref) => (
      <Clear {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Search: forwardRef((props, ref) => (
      <Search {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    SortArrow: forwardRef((props, ref) => (
      <MySort {...props} ref={ref} />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    ViewColumn: forwardRef((props, ref) => (
      <ViewColumn {...props} ref={ref} className="Icon" />
    )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  };

  const dispatch = useDispatch();
  const { openDialog, openFilter } = useUtility();

  const actions: (Action<any> | ((rowData: any) => Action<any>))[] = [
    {
      icon: () => <Sync />,
      tooltip: 'Atualizar',
      isFreeAction: true,
      hidden: !onRefresh,
      onClick: () => {
        if (onRefresh) {
          onRefresh();
        }
      },
    },
    {
      icon: () => <FilterList />,
      tooltip: 'Filtrar',
      isFreeAction: true,
      hidden: !showActionFilter,
      onClick: () => {
        dispatch(openFilter());
      },
    },
    {
      icon: () => <AddBox />,
      tooltip: 'Adicionar',
      isFreeAction: true,
      hidden: hideActionAdd,
      onClick: () => {
        dispatch(openDialog(''));
      },
    },
  ];

  if (!hideActionEdit) {
    actions.push({
      icon: () => <Edit />,
      tooltip: 'Editar',
      isFreeAction: false,
      hidden: false,
      onClick: (event: React.MouseEvent, rowData: any) => {
        if (isVerifyRegistrationAutomatict) {
          if (rowData.automatico) {
            snack.warning('Não é permitido alterar um cadastro automático');
            return;
          }
        }

        dispatch(openDialog(rowData.id));
      },
    });
  }

  if (!hideActionDelete) {
    actions.push({
      icon: () => (onActive ? <DoneAll /> : <Clear />),
      tooltip: onActive ? 'Ativar/Desativar' : 'Excluir',
      isFreeAction: false,
      hidden: false,
      onClick: (event: React.MouseEvent, rowData: any) => {
        if (onActive) {
          if (isVerifyRegistrationAutomatict) {
            if (rowData.automatico) {
              snack.warning('Não é permitido alterar um cadastro automático');
              return;
            }
          }

          onActive(rowData);
        } else {
          if (onDelete) {
            onDelete(rowData.id);
            return;
          }

          snack.warning('Método de exclusão inválido');
        }
      },
    });
  }

  if (onView) {
    actions.push({
      icon: () => <ArrowForward />,
      tooltip: 'Visualizar',
      isFreeAction: false,
      onClick: (event: React.MouseEvent, rowData: any) => {
        onView(rowData.id);
      },
    });
  }

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MaterialTable
            {...materialTableProps}
            icons={tableIcons}
            actions={actionsOverride || actions}
            localization={{
              pagination: {
                firstTooltip: 'Primeira página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página',
                labelDisplayedRows: '{from}-{to} de {count}',
              },
              toolbar: {
                nRowsSelected: '{0} registro(s) selecionados',
                searchPlaceholder: 'Pesquisar',
                searchTooltip: 'Pesquisar',
                addRemoveColumns: 'Adicionar ou remover',
                showColumnsTitle: 'Personalizar colunas',
                exportTitle: 'Exportar',
                exportCSVName: 'Exportar em CSV',
                exportPDFName: 'Exportar em PDF',
              },
              header: {
                actions: 'Ações',
              },
              body: {
                addTooltip: 'Novo',
                deleteTooltip: 'Excluir',
                editTooltip: 'Editar',

                emptyDataSourceMessage: (
                  <span>Não há registros a serem exibidos</span>
                ),
                filterRow: {
                  filterTooltip: 'Filtro',
                },
                editRow: {
                  saveTooltip: 'Gravar',
                  cancelTooltip: 'Cancelar',
                },
              },
            }}
            options={{
              headerStyle: { backgroundColor: '#F0F0F5', color: '#5e5e6e' },
              actionsCellStyle: { color: '#5e5e6e' },
              searchFieldStyle: { color: '#5e5e6e' },
              actionsColumnIndex: -1,
              pageSize: 6,
              pageSizeOptions: [],
              exportButton: false,
              exportAllData: true,
              columnsButton: false,
              sorting: true,
              draggable: false,
              search: !hideIsSearch,
              searchFieldAlignment: isRightSearch ? 'right' : 'left',
              tableLayout: isfixed ? 'fixed' : 'auto',
            }}
          />
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Container>
  );
};

export default MaterialTableX;
