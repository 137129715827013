import React from 'react';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { CircularProgress } from '@material-ui/core';
import { Container } from './styles';

interface Props {
  caption: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps & Props> = ({
  caption,
  loading,
  ...buttonProps
}) => (
  <Container
    {...buttonProps}
    variant="contained"
    color="primary"
    {...(loading && { disabled: true })}
  >
    {!loading && caption}
    {loading && <CircularProgress color="secondary" size={25} />}
  </Container>
);

export default Button;
