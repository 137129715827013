import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from './store';
import { IUtility } from '../models';

export const utilitySlice = createSlice({
  name: 'utility',
  initialState: {
    drawerOpen: false,
    dialogOpen: false,
    filterOpen: false,
    dialogId: '',
  } as IUtility,
  reducers: {
    openDrawer: (state: IUtility) => {
      state.drawerOpen = true;
      return state;
    },
    closeDrawer: (state: IUtility) => {
      state.drawerOpen = false;
      return state;
    },
    openDialog: (state: IUtility, action: PayloadAction<string>) => {
      state.dialogId = action.payload;
      state.dialogOpen = true;
      return state;
    },
    closeDialog: (state: IUtility) => {
      state.dialogId = '';
      state.dialogOpen = false;
      return state;
    },
    openFilter: (state: IUtility) => {
      state.filterOpen = true;
      return state;
    },
    closeFilter: (state: IUtility) => {
      state.filterOpen = false;
      return state;
    },
  },
});

export const {
  openDrawer,
  closeDrawer,
  openDialog,
  closeDialog,
  openFilter,
  closeFilter,
} = utilitySlice.actions;

// export const utilitySelector = (state: RootState) => state.user;
