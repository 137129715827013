import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
  Tooltip,
  Select,
  IconButton,
  TextField as TextFieldCore,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Clear, VisibilityOutlined } from '@material-ui/icons';
import useUtility from '../../hooks/useUtility';
import api from '../../services/api';
import Page from '../../components/Page';
import MaterialTableX from '../../components/MaterialTable';
import Dialog from '../../components/Dialog';
import DialogForm from '../../components/DialogForm';
import TextField from '../../components/TextField';
import { IDepartamento, IDepartamentoObservador, IUsuario } from '../../models';
import styles from './styles';

const schema = yup
  .object()
  .shape({
    descricao: yup.string().required('Este campo é obrigatório'),
    usuarioResp: yup.object().required('Este campo é obrigatório').nullable(),
  })
  .required();

const Department = () => {
  const history = useHistory();
  const classes = styles();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingObs, setIsLoadingObs] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector } = useUtility();
  const [data, setData] = useState<IDepartamento[]>([]);
  const [obs, setObs] = useState<IDepartamentoObservador[]>([]);
  const [select, setSelect] = useState<IDepartamento[]>([]);
  const [user, setUser] = useState<IUsuario[]>([]);
  const [userObs, setUserObs] = useState<IUsuario[]>([]);
  const [openObs, setOpenObs] = useState(false);
  const [departamento, setDepartamento] = useState<IDepartamento>();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDepartamento>({
    resolver: yupResolver(schema),
  });

  const actions = [
    {
      icon: () => <Clear />,
      tooltip: 'Excluir',
      isFreeAction: false,
      onClick: (event: React.MouseEvent, rowData: any) => {
        deleteDepartmentObs(rowData.id);
      },
    },
  ];

  const columns = [
    { title: 'Id', field: 'id', hidden: true },
    {
      title: 'Código',
      field: 'codigo',
      hidden: false,
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Descrição',
      field: 'descricao',
      hidden: false,
      cellStyle: {
        width: '48%',
      },
    },
    {
      title: 'Departamento Referência',
      field: 'departamentoReferencia',
      hidden: false,
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Usuário Responsável',
      field: 'nomeUsuarioResponsavel',
      hidden: false,
      cellStyle: {
        width: '20%',
      },
    },
    {
      title: 'Observadores',
      field: 'observadores',
      hidden: false,
      searchable: false,
      sorting: false,
      render: (rowData: IDepartamento) => (
        <div className={classes.divDocument}>
          {rowData.observadores?.toString().padStart(3, '0')}
          <IconButton
            onClick={() => {
              setDepartamento(rowData);
              departmentChildrenObs(rowData.id, rowData.usuarioRespId);
            }}
            size="small"
          >
            <Tooltip title="Visualizar Observadores" placement="bottom">
              <VisibilityOutlined fontSize="small" />
            </Tooltip>
          </IconButton>
        </div>
      ),
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Ativo',
      field: 'ativo',
      hidden: false,
      searchable: false,
      render: (rowData: IDepartamento) => (
        <Chip
          className={clsx(classes.active, !rowData.ativo && classes.inactive)}
          variant="outlined"
          size="small"
          label={rowData.ativo ? 'Sim' : 'Não'}
        />
      ),
      cellStyle: {
        width: '7%',
      },
    },
  ];

  const columnsObs = [{ title: 'Nome', field: 'nome', hidden: false }];

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }

    setIsLoading(true);
    getUsers();
    getDepartments(false);
  }, []);

  useEffect(() => {
    async function fetchDepartment() {
      await api
        .get<IDepartamento>(`api/Departamento/Get/${utilitySelector.dialogId}`)
        .then(result => {
          reset(result.data);
          setIsFetch(false);
        })
        .catch(() => {
          setIsFetch(false);
        });
    }

    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setIsFetch(true);
        fetchDepartment();
      }

      getSelectDepartments();
    }
  }, [utilitySelector.dialogOpen]);

  const getDepartments = async (loading = true) => {
    if (loading) {
      setIsLoading(true);
    }

    await api
      .get('/api/Departamento/GetAll')
      .then(result => {
        setData(result.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getSelectDepartments = async () => {
    await api
      .get(`/api/Departamento/GetAll/${utilitySelector.dialogId}`)
      .then(result => {
        setSelect(result.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getUsers = async () => {
    await api
      .get(`/api/Usuario/GetAll/?ativo=${true}&tipo=4`)
      .then(result => {
        setUser(result.data);
      })
      .catch(() => {
        setIsLoadingObs(false);
      });
  };

  const getDepartmentsObs = async (id: string) => {
    setIsLoadingObs(true);

    await api
      .get(`/api/DepartamentoObservador/GetAll/${id}`)
      .then(result => {
        setObs(result.data);
        setIsLoadingObs(false);
      })
      .catch(() => {
        setIsLoadingObs(false);
      });
  };

  const createDepartment = async (dep: IDepartamento) => {
    const obj = {
      usuarioRespId: dep.usuarioRespId,
      departamentoId: dep.departamentoId,
      descricao: dep.descricao,
      ativo: dep.ativo,
    };

    await api
      .post<IDepartamento>(`api/Departamento/Create`, obj)
      .then(result => {
        result.data.departamentoReferencia =
          select.find(x => x.id === obj.departamentoId)?.descricao || '';
        result.data.nomeUsuarioResponsavel =
          user.find(x => x.id === obj.usuarioRespId)?.nome || '';
        setData([...data, result.data]);
      });
  };

  const updateDepartment = async (dep: IDepartamento) => {
    const obj = {
      id: dep.id,
      codigo: dep.codigo,
      usuarioId: dep.usuarioId,
      usuarioRespId: dep.usuarioRespId,
      departamentoId: dep.departamentoId,
      empresaId: dep.empresaId,
      descricao: dep.descricao,
      nivel: dep.nivel,
      ativo: dep.ativo,
    };

    await api.put<IDepartamento>(`api/Departamento/Edit`, obj).then(result => {
      const updateTable = [...data];
      const index = updateTable.findIndex(x => x.id === obj.id);

      result.data.departamentoReferencia =
        select.find(x => x.id === obj.departamentoId)?.descricao || '';
      result.data.nomeUsuarioResponsavel =
        user.find(x => x.id === obj.usuarioRespId)?.nome || '';
      updateTable[index] = result.data;
      setData(updateTable);
    });
  };

  const activeDepartment = async (dep: IDepartamento) => {
    dep.ativo = !dep.ativo;
    await updateDepartment(dep);
  };

  const createDepartmentObs = async (depObs: IDepartamentoObservador[]) => {
    await api
      .post<IDepartamentoObservador[]>(
        `api/DepartamentoObservador/Create/${departamento?.id}`,
        depObs
      )
      .then(result => {
        const updateTable = [...data];
        const index = updateTable.findIndex(x => x.id === departamento?.id);
        updateTable[index].observadores = result.data.length;
        setData(updateTable);
      });
  };

  const deleteDepartmentObs = async (id: string) => {
    const updateTable = [...obs];
    const index = updateTable.findIndex(x => x.id === id);
    updateTable.splice(index, 1);
    setObs(updateTable);
  };

  const onSubmit = async (formData: IDepartamento) => {
    formData.departamentoId = formData.departamentoRef?.id;
    formData.usuarioRespId = formData.usuarioResp.id;
    formData.departamentoRef = null;

    if (utilitySelector.dialogId) {
      await updateDepartment(formData);
    } else {
      await createDepartment(formData);
    }

    if (!checked) {
      dispatch(closeDialog());
    }

    reset({});
  };

  const onSubmitObs = async () => {
    createDepartmentObs(obs);
    setOpenObs(false);
  };

  const handleClose = () => {
    dispatch(closeDialog());
    setChecked(false);
    reset({});
  };

  const handleCloseObs = () => {
    setOpenObs(false);
    setObs([]);
  };

  const departmentChildrenObs = (id: string, userId: string) => {
    const updateTable = [...user];
    setUserObs(updateTable.filter(x => x.id !== userId));

    getDepartmentsObs(id);
    setOpenObs(true);
  };

  const onChange = (event: any, value: any) => {
    if (value) {
      const updateTable = [...obs];
      const index = updateTable.findIndex(x => x.usuarioId === value.id);

      if (index >= 0) {
        updateTable[index].usuarioId = value.id;
        updateTable[index].departamentoId = departamento?.id || '';
        setObs(updateTable);
      } else {
        const obj = {
          usuarioId: value.id,
          departamentoId: departamento?.id,
          nome: value.nome,
        };

        setObs([...obs, obj as IDepartamentoObservador]);
      }
    }
  };

  return (
    <Page>
      <MaterialTableX
        title={<h2>Departamentos ({data.length})</h2>}
        columns={columns}
        data={data}
        isLoading={isLoading}
        onRefresh={getDepartments}
        onActive={activeDepartment}
      />
      <DialogForm
        titleDiag="Departamento"
        subTitle="Informações do Departamento"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          id="description"
          name="descricao"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Descrição"
          autoFocus
          InputLabelProps={{ shrink: true }}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              className={classes.autoComplete}
              clearOnEscape
              options={select}
              value={field.value || null}
              noOptionsText="Sem opções"
              openText="Abrir"
              clearText="Limpar"
              getOptionLabel={option => option.descricao}
              getOptionSelected={(option, value) => option.id === value.id}
              closeText="Fechar"
              renderInput={params => (
                <TextFieldCore
                  {...params}
                  label="Departamento Referência"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              onChange={(_, obj) => field.onChange(obj)}
              ref={field.ref}
            />
          )}
          name="departamentoRef"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              className={classes.autoComplete}
              clearOnEscape
              options={user}
              value={field.value || null}
              noOptionsText="Sem opções"
              openText="Abrir"
              clearText="Limpar"
              getOptionLabel={option => option.nome}
              getOptionSelected={(option, value) => option.id === value.id}
              closeText="Fechar"
              renderInput={params => (
                <TextFieldCore
                  {...params}
                  label="Usuário Reponsável"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              onChange={(_, obj) => field.onChange(obj)}
              ref={field.ref}
            />
          )}
          name="usuarioResp"
          control={control}
        />

        <FormControlLabel
          className={classes.formControlChecked}
          control={
            <Controller
              name="ativo"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Ativo"
        />
      </DialogForm>
      <Dialog
        titleDiag={`Departamento: ${departamento?.descricao}`}
        captionOk="Salvar"
        open={openObs}
        maxWidth="md"
        handleClose={handleCloseObs}
        onOk={onSubmitObs}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Autocomplete
              options={userObs}
              getOptionLabel={(option: IUsuario) => option.nome}
              style={{ marginTop: 23 }}
              noOptionsText="Sem opções"
              openText="Abrir"
              clearText="Limpar"
              clearOnEscape
              renderInput={params => (
                <TextFieldCore
                  {...params}
                  label="Adicionar Observador"
                  variant="outlined"
                  autoFocus
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              )}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <MaterialTableX
              title="Observadores"
              actionsOverride={actions}
              columns={columnsObs}
              data={obs}
              isLoading={isLoadingObs}
              style={{ marginTop: 20 }}
              hideColumnsButton
              hideExportButton
              isRightSearch
            />
          </Grid>
        </Grid>
      </Dialog>
    </Page>
  );
};

export default Department;
