import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '../Button';

const useStyles = makeStyles(theme => ({
  submitBtn: {
    width: 100,
    margin: theme.spacing(3, 0.5, 2),
  },
  optionsControl: {
    float: 'right',
  },
}));

interface Props {
  onSubmit: () => Promise<void>;
  onClose?: () => void;
  isSubmitting?: boolean;
}

const Form: React.FC<Props> = ({
  onSubmit,
  onClose,
  isSubmitting,
  children,
}) => {
  const classes = useStyles();

  return (
    <form className="form" onSubmit={onSubmit}>
      {children}

      <div className={classes.optionsControl}>
        <Button
          id="submit"
          className={classes.submitBtn}
          caption="Salvar"
          type="submit"
          loading={isSubmitting}
        />

        {onClose && (
          <Button
            id="cancel"
            className={classes.submitBtn}
            caption="Cancelar"
            onClick={onClose}
            {...(isSubmitting && { disabled: true })}
          />
        )}
      </div>
    </form>
  );
};

export default Form;
