import React from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  keySnack: SnackbarKey;
}

const CloseButton: React.FC<Props> = ({ keySnack }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Fechar notificação"
      color="inherit"
      onClick={() => closeSnackbar(keySnack)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export default CloseButton;
