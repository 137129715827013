import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 40,
    color: theme.palette.primary.main,
    textAlign: 'center',
    pointerEvents: 'none',
  },
}));

type IParams = CardProps & {
  value: number;
};

const Efficiency = ({ value, ...rest }: IParams) => {
  const classes = useStyles();

  const data = [
    {
      id: '1',
      label: '1',
      value,
    },
    {
      id: '2',
      label: '2',
      value: 100 - value,
    },
  ];

  return (
    <Card className={classes.root} {...rest}>
      <CardHeader
        title="Eficiência"
        titleTypographyProps={{ variant: 'body1' }}
      />
      <Divider />
      <CardContent>
        <Box height={238} position="relative">
          <ResponsivePie
            data={data}
            innerRadius={0.8}
            colors={['#000066', '#e6e6e6']}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            isInteractive={false}
          />
          <div className={classes.overlay}>
            <span>{value}%</span>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Efficiency;
