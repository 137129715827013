import api from './api';
import { ILogin } from '../models/index';

export const getAuthentication = async (username: string, password: string) => {
  const bodyFormData = new FormData();

  bodyFormData.append('username', username);
  bodyFormData.append('password', password);

  return api.post<ILogin>('/Token', bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const getUserInfo = async () => {
  return api.get<ILogin>('/api/Usuario/Fetch');
};
