import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  main: {
    backgroundColor: '#F0F0F5',
    minHeight: '92%',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarTab: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    fontSize: 9,
  },
  paper: {
    height: '100%',
    padding: 20,
  },
  divs: {
    marginTop: 15,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  autoComplete: {
    marginBottom: 20,
  },
  autoCompleteTop: {
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    marginBottom: 15,
    color: theme.palette.primary.main,
  },
  progress: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  editIcon: {
    marginLeft: 20,
  },
  infoIcon: {
    fontSize: 14,
    marginRight: 20,
  },
  okIcon: {
    fontSize: 16,
    marginRight: 10,
    color: 'green',
  },
  cancelIcon: {
    fontSize: 16,
    marginRight: 10,
  },
  iconAproved: {
    color: '#4d004d',
  },
  iconChecked: {
    color: '#006600',
  },
  iconPending: {
    color: '#808080',
  },
  iconInProgress: {
    color: theme.palette.primary.main,
  },
  iconOk: {
    color: '#006600',
  },
  iconCancel: {
    color: '#990000',
  },
  iconRequestCancel: {
    color: '#990000',
  },
  iconWait: {
    color: '#527a7a',
  },
  textSituation: {
    color: '#000000',
  },
  flexRoot: {
    height: '92%',
    display: 'flex',
    flexDirection: 'column',
  },
  flexSection: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  flexColScroll: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '92%',
  },
  oppositeContent: {
    flex: 0.1,
  },
  inputRoot: {
    '&$disabled': {
      color: '#6C6C80',
    },
    fontSize: 14,
  },
  disabled: {},
  infoDocument: {
    fontSize: 16,
  },
  grow: {
    flexGrow: 1,
    marginTop: 16,
    marginLeft: 5,
    marginRight: 5,
  },
  dropZoneContainer: {
    maxWidth: 600,
    padding: 30,
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  inheritedLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  errorLabel: {
    color: '#f44336',
  },
  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    marginTop: 3,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  tab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 20,
    paddingRight: 20,
  },
  appbar: {
    backgroundColor: 'transparent',
    paddingBottom: 10,
  },
  infoTab: {
    marginLeft: 3,
    marginTop: 3,
  },
  growFase: {
    flexGrow: 1,
  },
  iconPendingPhase: {
    backgroundColor: '#808080',
    color: '#FFFFFF',
  },
  iconWaitPhase: {
    backgroundColor: '#527a7a',
    color: '#FFFFFF',
  },
  iconInProgressPhase: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
  },
  iconAprovedPhase: {
    backgroundColor: '#4d004d',
    color: '#FFFFFF',
  },
  iconCheckedPhase: {
    backgroundColor: '#006600',
    color: '#FFFFFF',
  },
  iconCancelPhase: {
    backgroundColor: '#990000',
    color: '#FFFFFF',
  },
  colorwhite: {
    color: '#FFFFFF !important',
  },
  buttonCommentAction: {
    textTransform: 'none',
    marginTop: 8,
    marginLeft: 8,
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
  },
  buttonCommentCancel: {
    textTransform: 'none',
    marginTop: 8,
    marginLeft: 8,
    color: theme.palette.primary.main,
  },
  resize: {
    fontSize: 14,
  },
}));
