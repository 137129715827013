import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export const Container = withTheme(styled.div`
  .paper {
    margin: ${props => props.theme.spacing(8, 0, 0, 0)};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  form {
    width: 100%;
    margin: ${props => props.theme.spacing(1, 0, 0, 0)};
  }
  .submit {
    margin: theme.spacing(3, 0, 2);
  }
  .alert {
    margin: ${props => props.theme.spacing(2, 0, 0, 0)};
    width: 100%;
  }
  .button {
    margin: ${props => props.theme.spacing(3, 0, 2)};
  }
`);
