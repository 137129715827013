import React from 'react';
import { ResponsiveBar, ComputedDatum } from '@nivo/bar';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IDashboardPrioridade } from '../../models';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 40,
    color: theme.palette.primary.main,
    textAlign: 'center',
    pointerEvents: 'none',
  },
}));

type IParams = CardProps & {
  data: any[];
};

const TasksPriority = ({ data, ...rest }: IParams) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} {...rest}>
      <CardHeader
        action={
          <div style={{ marginTop: 10 }}>
            <Typography
              component="span"
              variant="caption"
              color="textSecondary"
            >
              Últimos 30 dias
            </Typography>
          </div>
        }
        title="Solicitações"
        titleTypographyProps={{ variant: 'body1' }}
      />
      <Divider />
      <CardContent>
        <Box height={238} position="relative">
          {data.length > 0 ? (
            <ResponsiveBar
              data={data}
              keys={['quantidade']}
              indexBy="prioridade"
              margin={{ top: 10, right: 15, bottom: 30, left: 50 }}
              padding={0.4}
              valueScale={{ type: 'linear' }}
              colors={d => d.data.color}
              animate
              axisLeft={{
                format: e => Math.floor(e) === e && e,
                tickSize: 0,
              }}
              axisTop={null}
              axisRight={null}
              tooltip={({ id, value, color }) => (
                <div
                  style={{
                    padding: 12,
                    color,
                    background: '#222222',
                  }}
                >
                  <span>Nível de Prioridade</span>
                  <br />
                  <strong>Quantidade: {value}</strong>
                </div>
              )}
            />
          ) : (
            <div className={classes.overlay}>
              <Typography
                component="span"
                variant="body1"
                color="textSecondary"
              >
                Sem Informações
              </Typography>
            </div>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TasksPriority;
