import React from 'react';
import { useLocation } from 'react-router';
import {
  Dashboard,
  PeopleAlt,
  PostAdd,
  Category,
  Business,
  DynamicFeed,
} from '@material-ui/icons';
import ListItem from '@material-ui/core/ListItem';
import { ListSubheader, Tooltip } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';

export const MenuItems: React.FC = () => {
  const location = useLocation();
  const { userSelector } = useLogin();

  return (
    <>
      <ListItem
        button
        selected={location.pathname === '/dashboard'}
        component={Link}
        to="/dashboard"
      >
        <ListItemIcon>
          <Tooltip title="Dashboard" placement="right-start">
            <Dashboard />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {(userSelector.tipo === '1' || userSelector.tipo === '3') && (
        <>
          <ListItem
            button
            selected={location.pathname === '/departamentos'}
            component={Link}
            to="/departamentos"
          >
            <ListItemIcon>
              <Tooltip title="Departamentos" placement="right-start">
                <Category />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Departamentos" />
          </ListItem>

          <ListItem
            button
            selected={location.pathname === '/filiais'}
            component={Link}
            to="/filiais"
          >
            <ListItemIcon>
              <Tooltip title="Filiais" placement="right-start">
                <Business />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Filiais" />
          </ListItem>

          <ListItem
            button
            selected={location.pathname === '/tipossolicitacoes'}
            component={Link}
            to="/tipossolicitacoes"
          >
            <ListItemIcon>
              <Tooltip title="Tipos de Solicitações" placement="right-start">
                <DynamicFeed />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Tipos de Solicitações" />
          </ListItem>

          <ListItem
            button
            selected={location.pathname === '/usuarios'}
            component={Link}
            to="/usuarios"
          >
            <ListItemIcon>
              <Tooltip title="Usuários" placement="right-start">
                <PeopleAlt />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Usuários" />
          </ListItem>
        </>
      )}
    </>
  );
};

export const SecondaryMenuItems = () => {
  const location = useLocation();

  return (
    <>
      <ListSubheader inset>Central</ListSubheader>
      <ListItem
        button
        selected={location.pathname === '/solicitacoes'}
        component={Link}
        to="/solicitacoes"
      >
        <ListItemIcon>
          <Tooltip title="Solicitações" placement="right-start">
            <PostAdd />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Solicitações" />
      </ListItem>
    </>
  );
};
