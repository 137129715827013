import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export const Container = withTheme(styled.div`
  text-align: center;

  .Icon {
    // color: #000066;
  }
`);
