import React from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface Props {
  label: string;
}

const DatePicker: React.FC<Props & UseControllerProps<any>> = ({
  label,
  name,
  control,
  rules,
  defaultValue,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <KeyboardDatePicker
        {...field}
        {...rest}
        disableToolbar
        fullWidth
        autoOk
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        label={label}
        invalidDateMessage="Data inválida"
        helperText={error ? 'Data inválida' : null}
        error={!!error}
        value={field.value || null}
        ref={field.ref}
        InputLabelProps={{ shrink: true }}
      />
    )}
  />
);

export default DatePicker;
