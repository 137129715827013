import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Avatar,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  TextField as TextFieldCore,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useUtility from '../../hooks/useUtility';
import api from '../../services/api';
import Page from '../../components/Page';
import MaterialTableX from '../../components/MaterialTable';
import DialogForm from '../../components/DialogForm';
import PasswordField from '../../components/PasswordField';
import TextField from '../../components/TextField';
import { IUsuario } from '../../models';
import getInitials from '../../utils/getInitials';
import styles from './styles';

const User = () => {
  const schema = yup
    .object()
    .shape({
      solicitarAprovacaoResponsavel: yup.boolean().required(),
      senha: yup
        .string()
        .max(50, 'O campo tem a capacidade máxima de 50 caracteres')
        .test(
          'pass',
          'A senha deve conter no mínimo 8 caracteres, maiúsculo, minúsculo, número e caractere especial',
          val => {
            const rExp =
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            const addValidate =
              utilitySelector.dialogId === '' && val && rExp.test(val);
            const editValidate =
              utilitySelector.dialogId !== '' &&
              (!val || val?.length === 0 || rExp.test(val));

            return addValidate || editValidate;
          }
        ),
      usuarioResp: yup
        .object()
        .nullable()
        .when('solicitarAprovacaoResponsavel', {
          is: true,
          then: yup.object().required('Este campo é obrigatório').nullable(),
        }),
    })
    .required();

  const history = useHistory();
  const classes = styles();
  const [isLoading, setIsLoading] = useState(false);
  const [isUserResp, setIsUserResp] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<IUsuario>({
    resolver: yupResolver(schema),
  });
  const [checked, setChecked] = useState(false);
  const [allowCancelRequest, setAllowCancelRequest] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector } = useUtility();
  const [data, setData] = useState<IUsuario[]>([]);
  const [userX, setUserX] = useState<IUsuario[]>([]);

  const columns = [
    { title: 'Id', field: 'id', hidden: true },
    {
      title: 'Avatar',
      field: 'urlAvatar',
      hidden: false,
      sorting: false,
      render: (rowData: any) =>
        rowData.urlAvatar ? (
          <img
            src={rowData.urlAvatar}
            alt="avatar"
            style={{ width: 30, borderRadius: '50%' }}
          />
        ) : (
          <Avatar className={classes.small}>{getInitials(rowData.nome)}</Avatar>
        ),
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Código',
      field: 'codigo',
      hidden: false,
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Nome',
      field: 'nome',
      hidden: false,
      cellStyle: {
        width: '35%',
      },
    },
    {
      title: 'Login',
      field: 'login',
      hidden: false,
      cellStyle: {
        width: '17%',
      },
    },
    {
      title: 'Tipo',
      field: 'descricaoTipo',
      hidden: false,
      cellStyle: {
        width: '10%',
      },
    },
    {
      title: 'Aprov. Solicitações',
      field: 'permiteAprovarSolicitacao',
      hidden: false,
      searchable: false,
      render: (rowData: IUsuario) => (
        <div className={classes.divDocument}>
          <Chip
            className={clsx(
              classes.active,
              !rowData.permiteAprovarSolicitacao && classes.inactive
            )}
            variant="outlined"
            size="small"
            label={rowData.permiteAprovarSolicitacao ? 'Sim' : 'Não'}
          />
        </div>
      ),
      cellStyle: {
        width: '11%',
      },
    },
    {
      title: 'Canc. Solicitações',
      field: 'permiteCancelarSolicitacao',
      hidden: false,
      searchable: false,
      render: (rowData: IUsuario) => (
        <div className={classes.divDocument}>
          <Chip
            className={clsx(
              classes.active,
              !rowData.permiteCancelarSolicitacao && classes.inactive
            )}
            variant="outlined"
            size="small"
            label={rowData.permiteCancelarSolicitacao ? 'Sim' : 'Não'}
          />
        </div>
      ),
      cellStyle: {
        width: '11%',
      },
    },
    {
      title: 'Necessita Aprovação',
      field: 'solicitarAprovacaoResponsavel',
      hidden: false,
      searchable: false,
      render: (rowData: IUsuario) => (
        <div className={classes.divDocument}>
          <Chip
            className={clsx(
              classes.active,
              !rowData.solicitarAprovacaoResponsavel && classes.inactive
            )}
            variant="outlined"
            size="small"
            label={rowData.solicitarAprovacaoResponsavel ? 'Sim' : 'Não'}
          />
        </div>
      ),
      cellStyle: {
        width: '11%',
      },
    },
    {
      title: 'Ativo',
      field: 'ativo',
      hidden: false,
      searchable: false,
      render: (rowData: IUsuario) => (
        <Chip
          className={clsx(classes.active, !rowData.ativo && classes.inactive)}
          variant="outlined"
          size="small"
          label={rowData.ativo ? 'Sim' : 'Não'}
        />
      ),
      cellStyle: {
        width: '5%',
      },
    },
  ];

  const getUsers = async () => {
    setIsLoading(true);

    await api
      .get('/api/Usuario/GetAll')
      .then(result => {
        setData(result.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getUsersRef = async () => {
    await api
      .get(`/api/Usuario/GetAll/?ativo=${true}&ignoreActualUser=${true}`)
      .then(result => {
        setUserX(result.data);
      })
      .catch(() => {
        setIsFetch(false);
      });
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }

    getUsers();
  }, []);

  useEffect(() => {
    setAllowCancelRequest(false);
    setIsSupervisor(false);
    setIsUserResp(false);

    async function fetchUser() {
      await api
        .get<IUsuario>(`api/Usuario/Get/${utilitySelector.dialogId}`)
        .then(result => {
          reset(result.data);
          setAllowCancelRequest(result.data.tipo === '4');
          setIsSupervisor(result.data.tipo === '1');
          setIsUserResp(result.data.solicitarAprovacaoResponsavel);
          setIsFetch(false);
        })
        .catch(() => {
          setIsFetch(false);
        });
    }

    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setIsFetch(true);
        fetchUser();
      }

      getUsersRef();
    }
  }, [utilitySelector.dialogOpen]);

  const onSubmit = async (formData: IUsuario) => {
    formData.usuarioRespId = formData.usuarioResp
      ? formData.usuarioResp.id
      : null;
    formData.usuarioResp = null;

    if (utilitySelector.dialogId) {
      await updateUser(formData);
    } else {
      await createUser(formData);
    }

    if (!checked) {
      dispatch(closeDialog());
    }

    reset({});
  };

  const handleClose = () => {
    dispatch(closeDialog());
    setChecked(false);
    reset({});
  };

  const createUser = async (user: IUsuario) => {
    await api.post<IUsuario>(`api/Usuario/Create`, user).then(result => {
      result.data.nomeUsuarioResponsavel =
        userX.find(x => x.id === user.usuarioRespId)?.nome || '';
      setData([...data, result.data]);
    });
  };

  const updateUser = async (user: IUsuario) => {
    await api.put<IUsuario>(`api/Usuario/Edit`, user).then(result => {
      const updateTable = [...data];
      const index = updateTable.findIndex(x => x.id === user.id);

      result.data.nomeUsuarioResponsavel =
        userX.find(x => x.id === user.usuarioRespId)?.nome || '';
      updateTable[index] = result.data;
      setData(updateTable);
    });
  };

  const activeUser = async (user: IUsuario) => {
    user.ativo = !user.ativo;
    await updateUser(user);
  };

  return (
    <Page>
      <MaterialTableX
        title={<h2>Usuários ({data.length})</h2>}
        columns={columns}
        data={data}
        isLoading={isLoading}
        onRefresh={getUsers}
        onActive={activeUser}
        isVerifyRegistrationAutomatict
        detailPanel={(rowData: IUsuario) => {
          return (
            <Grid container spacing={2} className={classes.grid}>
              <Grid item md={4} xs={4}>
                <Typography component="span" variant="subtitle2">
                  Responsável: {rowData.nomeUsuarioResponsavel}
                </Typography>
              </Grid>
              <Grid item md={4} xs={4}>
                <Typography component="span" variant="subtitle2">
                  Automático: {rowData.automatico ? 'Sim' : 'Não'}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <DialogForm
        titleDiag="Usuário"
        subTitle="Informações do usuário"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          id="name"
          name="nome"
          control={control}
          defaultValue=""
          rules={{
            required: 'Este campo é obrigatório',
            maxLength: {
              value: 200,
              message: 'O campo tem a capacidade máxima de 200 caracteres',
            },
          }}
          variant="standard"
          fullWidth
          label="Usuário"
          autoFocus
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          id="login"
          name="login"
          control={control}
          defaultValue=""
          rules={{
            required: 'Este campo é obrigatório',
            maxLength: {
              value: 200,
              message: 'O campo tem a capacidade máxima de 200 caracteres',
            },
          }}
          variant="standard"
          fullWidth
          label="Login"
          InputLabelProps={{ shrink: true }}
        />

        <PasswordField
          id="password"
          name="senha"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Senha"
          type="password"
          autoComplete="current-password"
          InputLabelProps={{ shrink: true }}
        />

        <FormControl
          variant="standard"
          className={classes.formControl}
          fullWidth
        >
          <InputLabel htmlFor="type-required" shrink>
            Tipo
          </InputLabel>
          <Controller
            name="tipo"
            control={control}
            defaultValue="2"
            render={({ field }) => (
              <Select
                {...field}
                id="type"
                variant="standard"
                color="primary"
                label="Tipo"
                className={classes.selectControl}
                onChange={event => {
                  reset({
                    ...getValues(),
                    permiteAprovarSolicitacao: true,
                    permiteCancelarSolicitacao: false,
                    solicitarAprovacaoResponsavel:
                      event.target.value === '1'
                        ? false
                        : getValues('solicitarAprovacaoResponsavel'),
                    usuarioResp:
                      event.target.value === '1'
                        ? null
                        : getValues('usuarioResp'),
                  });

                  setAllowCancelRequest(event.target.value === '4');
                  setIsSupervisor(event.target.value === '1');

                  if (event.target.value === '1') {
                    setIsUserResp(false);
                  }

                  field.onChange(event.target.value);
                }}
                value={field.value || '2'}
              >
                <MenuItem value="1">
                  <Typography>Supervisor</Typography>
                </MenuItem>
                <MenuItem value="2">
                  <Typography>Usuário</Typography>
                </MenuItem>
                <MenuItem value="4">
                  <Typography>Técnico</Typography>
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <FormControlLabel
          className={classes.formControlChecked}
          hidden={!allowCancelRequest}
          control={
            <Controller
              name="permiteAprovarSolicitacao"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="allowApproveRequest"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Permite Aprovar Solicitações"
        />

        <FormControlLabel
          className={classes.formControlChecked}
          hidden={!allowCancelRequest}
          control={
            <Controller
              name="permiteCancelarSolicitacao"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="allowCancelRequest"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Permite Cancelar Solicitações"
        />

        <FormControlLabel
          className={classes.formControlChecked}
          hidden={isSupervisor}
          control={
            <Controller
              name="solicitarAprovacaoResponsavel"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="requestApprove"
                  color="primary"
                  onChange={e => {
                    reset({
                      ...getValues(),
                      usuarioResp: null,
                    });

                    setIsUserResp(e.target.checked);
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Necessita de Aprovação"
        />

        {isUserResp && !isSupervisor && (
          <Controller
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                className={classes.autoComplete}
                clearOnEscape
                options={userX}
                value={field.value || null}
                noOptionsText="Sem opções"
                openText="Abrir"
                clearText="Limpar"
                getOptionLabel={option => option.nome}
                getOptionSelected={(option, value) => option.id === value.id}
                closeText="Fechar"
                renderInput={params => (
                  <TextFieldCore
                    {...params}
                    label="Usuário Reponsável"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
                onChange={(_, obj) => field.onChange(obj)}
                ref={field.ref}
              />
            )}
            name="usuarioResp"
            control={control}
          />
        )}

        <FormControlLabel
          className={classes.formControlChecked}
          control={
            <Controller
              name="ativo"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="active"
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Ativo"
        />
      </DialogForm>
    </Page>
  );
};

export default User;
