import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  active: {
    color: '#ffffff',
    borderColor: '#009900',
    backgroundColor: '#009900',
  },
  inactive: {
    color: '#ffffff',
    borderColor: '#ff1a1a',
    backgroundColor: '#ff1a1a',
  },
  formControlChecked: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));
