import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import {
  loginUser,
  clearState,
  logout,
  setUser,
  fetchUserBytoken,
} from '../store/userLogin';

export default function useLogin() {
  const dispatch = useDispatch();

  const onLogin = useCallback(
    async (user: string, password: string) => {
      const data = { username: user, password };

      dispatch(loginUser(data));
    },
    [dispatch]
  );

  const onGetUserInfo = useCallback(async () => {
    dispatch(fetchUserBytoken());
  }, [dispatch]);

  const userSelector = useSelector((state: RootState) => state.user);

  return {
    onLogin,
    onGetUserInfo,
    userSelector,
    clearState,
    logout,
    setUser,
  };
}
