import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { RootState } from '../../store/store';
import api from '../../services/api';
import PasswordField from '../../components/PasswordField';
import DialogForm from '../../components/DialogForm';
import TextField from '../../components/TextField';
import Form from '../../components/Form';
import snack from '../../components/SnackbarUtilsConfigurator';
import useUtility from '../../hooks/useUtility';
import { IUsuario } from '../../models';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  card: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface IChangePassword {
  id: string;
  senhaAtual: string;
  senhaNova: string;
  senhaConfirm: string;
}

const schema = yup
  .object()
  .shape({
    nome: yup.string().required('Este campo é obrigatório'),
    login: yup.string().required('Este campo é obrigatório'),
  })
  .required();

const schemaPass = yup
  .object()
  .shape({
    senhaAtual: yup.string().required('Este campo é obrigatório'),
    senhaNova: yup
      .string()
      .required('Este campo é obrigatório')
      .max(50, 'O campo tem a capacidade máxima de 50 caracteres')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'A senha deve conter no mínimo 8 caracteres, maiúsculo, minúsculo, número e caractere especial'
      ),
    senhaConfirm: yup
      .string()
      .oneOf([yup.ref('senhaNova'), null], 'Senha de confirmação não confere')
      .required('Este campo é obrigatório'),
  })
  .required();

const ProfileDetails = () => {
  const classes = useStyles();
  const [isFetch, setIsFetch] = useState(false);
  const { closeDialog, utilitySelector } = useUtility();
  const { setUser, userSelector } = useLogin();
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<IUsuario>({
    resolver: yupResolver(schema),
  });

  const {
    control: controlPass,
    handleSubmit: handleSubmitPass,
    reset: resetPass,
    formState: { isSubmitting: isSubmittingPass },
  } = useForm<IChangePassword>({
    resolver: yupResolver(schemaPass),
  });

  useEffect(() => {
    async function fetchUser() {
      await api
        .get<IUsuario>(`api/Usuario/Get/${userSelector.id}`)
        .then(result => reset(result.data));
    }

    if (userSelector.id) {
      setIsFetch(true);
      try {
        fetchUser();
      } finally {
        setIsFetch(false);
      }
    }
  }, [userSelector.id]);

  const updateUser = async (userData: IUsuario) => {
    await api.put<IUsuario>(`api/Usuario/Edit`, userData).then(result => {
      if (result.status === 201) {
        dispatch(setUser(result.data));
        snack.success('Informações atualizadas com sucesso');
      }
    });
  };

  const onSubmit = async (formData: IUsuario) => {
    await updateUser(formData);
  };

  const onSubmitPass = async (formData: IChangePassword) => {
    formData.id = userSelector.id;

    await api
      .post<IChangePassword>(`api/Usuario/ChangePassword`, formData)
      .then(result => {
        if (result.status === 201) {
          snack.success('Informações atualizadas com sucesso');
          dispatch(closeDialog());
        }
      });
  };

  const handleClose = () => {
    resetPass({});
    dispatch(closeDialog());
  };

  return (
    <div className={classes.root}>
      <Form onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
        <Card className={classes.card}>
          <CardHeader subheader="Informações do usuário" title="Perfil" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  name="nome"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Este campo é obrigatório',
                    maxLength: {
                      value: 200,
                      message:
                        'O campo tem a capacidade máxima de 200 caracteres',
                    },
                  }}
                  variant="standard"
                  fullWidth
                  label="Usuário"
                  autoFocus
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="login"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'Este campo é obrigatório',
                    maxLength: {
                      value: 200,
                      message:
                        'O campo tem a capacidade máxima de 200 caracteres',
                    },
                  }}
                  variant="standard"
                  fullWidth
                  label="Login"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Form>
      <DialogForm
        titleDiag="Alterar senha"
        subTitle="Informações do usuário"
        open={utilitySelector.dialogOpen}
        handleClose={handleClose}
        onSubmit={handleSubmitPass(onSubmitPass)}
        isLoading={isFetch}
        isSubmitting={isSubmittingPass}
      >
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <PasswordField
              name="senhaAtual"
              control={controlPass}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Senha Atual"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PasswordField
              name="senhaNova"
              control={controlPass}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Senha Nova"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PasswordField
              name="senhaConfirm"
              control={controlPass}
              defaultValue=""
              variant="standard"
              fullWidth
              label="Confirmar Senha"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </DialogForm>
    </div>
  );
};

export default ProfileDetails;
