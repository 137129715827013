import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  CircularProgress,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Form from '../Form';
import { Container } from './styles';

interface Props {
  titleDiag: string;
  subTitle?: string;
  isLoading?: boolean;
  handleClose: () => void;
  onSubmit: () => Promise<void>;
  isSubmitting?: boolean;
}

const DialogForm: React.FC<DialogProps & Props> = ({
  titleDiag,
  subTitle,
  isLoading,
  handleClose,
  onSubmit,
  isSubmitting,
  children,
  ...dialogProps
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      handleClose();
    }
  };

  return (
    <Container
      {...dialogProps}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{titleDiag}</DialogTitle>
      <DialogContent>
        {subTitle && <DialogContentText>{subTitle}</DialogContentText>}
        {isLoading ? (
          <div className="progress">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Form
            onClose={handleClose}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          >
            {children}
          </Form>
        )}
      </DialogContent>
    </Container>
  );
};

export default DialogForm;
