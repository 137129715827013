import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  submitBtn: {
    width: 100,
    margin: theme.spacing(3, 0.5, 2),
  },
  optionsControl: {
    float: 'right',
  },
}));

interface Props {
  index: number;
  value: number;
}

const TabPanel: React.FC<Props> = ({ index, value, children, ...rest }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  );
};

export default TabPanel;
