import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 40,
    color: theme.palette.primary.main,
    textAlign: 'center',
    pointerEvents: 'none',
  },
}));

type IParams = CardProps & {
  data: any[];
};

const TasksBranch = ({ data, ...rest }: IParams) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} {...rest}>
      <CardHeader
        action={
          <div style={{ marginTop: 10 }}>
            <Typography
              component="span"
              variant="caption"
              color="textSecondary"
            >
              Últimos 30 dias
            </Typography>
          </div>
        }
        title="Solicitações Abertas"
        titleTypographyProps={{ variant: 'body1' }}
      />
      <Divider />
      <CardContent>
        <Box height={238} position="relative">
          {data.length > 0 ? (
            <ResponsiveBar
              data={data}
              keys={['quantidade']}
              indexBy="filial"
              layout="horizontal"
              margin={{ top: 0, right: 300, bottom: 30, left: 10 }}
              padding={0.4}
              valueScale={{ type: 'linear' }}
              colors={[
                '#bbdefb',
                '#90caf9',
                '#64b5f6',
                '#2196f3',
                '#1565c0',
                '#0d47a1',
                '#37474f',
                '#546e7a',
                '#78909c',
                '#90a4ae',
                '#b0bec5',
                '#cfd8dc',
                '#81d4fa',
                '#29b6f6',
                '#0288d1',
              ]}
              colorBy="indexValue"
              animate
              axisBottom={{
                format: e => Math.floor(e) === e && e,
                tickSize: 0,
              }}
              axisTop={null}
              axisLeft={null}
              axisRight={null}
              legends={[
                {
                  dataFrom: 'indexes',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 12,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 10,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          ) : (
            <div className={classes.overlay}>
              <Typography
                component="span"
                variant="body1"
                color="textSecondary"
              >
                Sem Informações
              </Typography>
            </div>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TasksBranch;
