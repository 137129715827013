import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Badge from '@material-ui/core/Badge';
import useUtility from '../../hooks/useUtility';
import AvatarMenu from '../AvatarMenu';
import { MenuItems, SecondaryMenuItems } from '../MenuItems';
import useLogin from '../../hooks/useLogin';
import styles from './styles';

const AppMenu: React.FC = ({ children }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { openDrawer, closeDrawer, utilitySelector } = useUtility();
  const { userSelector, logout } = useLogin();
  const history = useHistory();

  const resizeChecker = () => {
    if (handleDrawerResponsive()) {
      dispatch(openDrawer());
    } else {
      dispatch(closeDrawer());
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeChecker);

    return () => {
      window.addEventListener('resize', resizeChecker);
    };
  }, []);

  const handleDrawerOpen = () => {
    dispatch(openDrawer());
  };

  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  const handleDrawerResponsive = () => {
    if (window.innerWidth < 900) {
      return false;
    }

    return true;
  };

  const redirectHomePage = () => {
    history.push('/dashboard');
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(
          classes.appBar,
          utilitySelector.drawerOpen && classes.appBarShift
        )}
      >
        <Toolbar
          className={classes.toolbar}
          disableGutters={!utilitySelector.drawerOpen}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              utilitySelector.drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Hidden mdDown>
            <Typography
              className={classes.typographyTitle}
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              onClick={redirectHomePage}
            >
              Help Desk | {userSelector.descricaoEmpresa}
            </Typography>
          </Hidden>

          <div className={classes.grow} />

          <div className={classes.appbarSection}>
            <AvatarMenu />
          </div>
        </Toolbar>
      </AppBar>
      <Hidden mdDown={!utilitySelector.drawerOpen && true}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(
              classes.drawerPaper,
              !utilitySelector.drawerOpen && classes.drawerPaperClose
            ),
          }}
          open={utilitySelector.drawerOpen}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List className={classes.list}>
            <MenuItems />
          </List>
          <Divider />
          <List className={classes.list}>
            <SecondaryMenuItems />
          </List>
        </Drawer>
      </Hidden>
      <main className={classes.main}>
        <div className={classes.toolbarIcon} />
        {children}
      </main>
    </div>
  );
};

export default AppMenu;
