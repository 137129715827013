import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { withTheme } from '@material-ui/core/styles';

export const Container = withTheme(styled(Dialog)`
  .progress {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
`);
