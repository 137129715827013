import React from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import VpnKey from '@material-ui/icons/VpnKey';
import { useDispatch } from 'react-redux';
import Button from '../../components/Button';
import useUtility from '../../hooks/useUtility';
import getInitials from '../../utils/getInitials';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    height: 100,
    width: 100,
    marginBottom: theme.spacing(1),
    fontSize: '50px',
  },
  fileInput: {
    display: 'none',
  },
}));

const Profile = () => {
  const classes = useStyles();
  const { userSelector } = useLogin();
  const { openDialog } = useUtility();
  const dispatch = useDispatch();

  const EditPassword = () => {
    dispatch(openDialog(userSelector.id));
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          {userSelector.urlAvatar ? (
            <Avatar
              className={classes.avatar}
              alt={userSelector.nome}
              src={userSelector.urlAvatar}
            />
          ) : (
            <Avatar className={classes.avatar}>
              {getInitials(userSelector.nome)}
            </Avatar>
          )}
          <Typography color="textPrimary" gutterBottom variant="h6" noWrap>
            {userSelector.nome.substring(0, 20)}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {userSelector.descricaoTipo}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          fullWidth
          caption="Alterar senha"
          startIcon={<VpnKey />}
          onClick={EditPassword}
        />
      </CardActions>
    </Card>
  );
};

export default Profile;
