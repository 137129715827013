import React from 'react';
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import User from './pages/User';
import Account from './pages/Account';
import Department from './pages/Department';
import Branch from './pages/Branch';
import Requests from './pages/Requests';
import ViewRequest from './pages/ViewRequest';
import RequestType from './pages/RequestType';
import NotFound from './pages/NotFound';

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/departamentos" component={Department} />
        <Route path="/filiais" component={Branch} />
        <Route path="/perfil" component={Account} />
        <Route path="/solicitacoes" component={Requests} exact />
        <Route path="/solicitacoes/:id" component={ViewRequest} />
        <Route path="/tipossolicitacoes" component={RequestType} />
        <Route path="/usuarios" component={User} />
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
