import axios from 'axios';
import snack from '../components/SnackbarUtilsConfigurator';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    const token = localStorage.getItem('token');

    if (error.response) {
      if (token) {
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/';
        } else if (error.response.status === 500) {
          snack.error(error.response.data);
        }
      }
    } else {
      snack.error('Erro na comunicação com o servidor');
      localStorage.removeItem('token');
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

export default api;
