import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getAuthentication, getUserInfo } from '../services/auth';
import { IUserInfo, IUsuario } from '../models';
// import { RootState } from './store';

export const loginUser = createAsyncThunk(
  'users/login',
  async (
    { username, password }: { username: string; password: string },
    thunkAPI
  ) => {
    try {
      const response = await getAuthentication(username, password);
      const { token } = response.data;

      if (response.status === 200) {
        localStorage.setItem('token', token);
        return response.data;
      }

      return thunkAPI.rejectWithValue(response.data);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(
        e.response?.data || 'Erro na autenticação dos dados.'
      );
    }
  }
);

export const fetchUserBytoken = createAsyncThunk(
  'users/fetchUserByToken',
  async (_, thunkAPI) => {
    try {
      const response = await getUserInfo();

      if (response.status === 200) {
        return { ...response.data };
      }

      return thunkAPI.rejectWithValue(response.data);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(
        e.response?.data || 'Erro na autenticação dos dados.'
      );
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: '',
    nome: '',
    login: '',
    empresaId: '',
    descricaoEmpresa: '',
    urlAvatar: '',
    tipo: '',
    descricaoTipo: '',
    permiteAprovarSolicitacao: false,
    permiteCancelarSolicitacao: false,
    solicitarAprovacaoResponsavel: false,
    ativo: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  } as IUserInfo,
  reducers: {
    clearState: state => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    logout: state => {
      state.id = '';
      state.nome = '';
      state.login = '';
      state.empresaId = '';
      state.descricaoEmpresa = '';
      state.urlAvatar = '';
      state.tipo = '';
      state.descricaoTipo = '';
      state.permiteAprovarSolicitacao = false;
      state.permiteCancelarSolicitacao = false;
      state.solicitarAprovacaoResponsavel = false;
      state.ativo = false;
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = '';

      localStorage.removeItem('token');
      localStorage.removeItem('filterRequest');
      // window.location.href = '/';

      return state;
    },
    setUser: (state: IUserInfo, action: PayloadAction<IUsuario>) => {
      state.nome = action.payload.nome;
      state.login = action.payload.login;
    },
  },
  extraReducers: {
    [loginUser.fulfilled.type]: (state, { payload }) => {
      state.id = payload.id;
      state.nome = payload.nome;
      state.login = payload.login;
      state.empresaId = payload.empresaId;
      state.descricaoEmpresa = payload.descricaoEmpresa;
      state.urlAvatar = '';
      state.tipo = payload.tipo;
      state.descricaoTipo = payload.descricaoTipo;
      state.permiteAprovarSolicitacao = payload.permiteAprovarSolicitacao;
      state.permiteCancelarSolicitacao = payload.permiteCancelarSolicitacao;
      state.solicitarAprovacaoResponsavel =
        payload.solicitarAprovacaoResponsavel;
      state.ativo = payload.ativo;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected.type]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload;
    },
    [loginUser.pending.type]: state => {
      state.isFetching = true;
    },
    [fetchUserBytoken.pending.type]: state => {
      state.isFetching = true;
    },
    [fetchUserBytoken.fulfilled.type]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;

      state.id = payload.id;
      state.nome = payload.nome;
      state.login = payload.login;
      state.empresaId = payload.empresaId;
      state.descricaoEmpresa = payload.descricaoEmpresa;
      state.urlAvatar = payload.urlAvatar;
      state.tipo = payload.tipo;
      state.descricaoTipo = payload.descricaoTipo;
      state.permiteAprovarSolicitacao = payload.permiteAprovarSolicitacao;
      state.permiteCancelarSolicitacao = payload.permiteCancelarSolicitacao;
      state.solicitarAprovacaoResponsavel =
        payload.solicitarAprovacaoResponsavel;
      state.ativo = payload.ativo;
    },
    [fetchUserBytoken.rejected.type]: state => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const { clearState, logout, setUser } = userSlice.actions;

// export const userSelector = (state: RootState) => state.user;
