import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Checkbox, Chip, FormControlLabel } from '@material-ui/core';
import useUtility from '../../hooks/useUtility';
import api from '../../services/api';
import Page from '../../components/Page';
import MaterialTableX from '../../components/MaterialTable';
import DialogForm from '../../components/DialogForm';
import TextField from '../../components/TextField';
import { ITipoSolicitacao } from '../../models';
import styles from './styles';

const schema = yup
  .object()
  .shape({
    descricao: yup.string().required('Este campo é obrigatório'),
  })
  .required();

const RequestType = () => {
  const history = useHistory();
  const classes = styles();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetch, setIsFetch] = useState(false);
  const dispatch = useDispatch();
  const { closeDialog, utilitySelector } = useUtility();
  const [data, setData] = useState<ITipoSolicitacao[]>([]);

  const { control, handleSubmit, reset } = useForm<ITipoSolicitacao>({
    resolver: yupResolver(schema),
  });

  const columns = [
    { title: 'Id', field: 'id', hidden: true },
    {
      title: 'Código',
      field: 'codigo',
      hidden: false,
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Descrição',
      field: 'descricao',
      hidden: false,
      cellStyle: {
        width: '85%',
      },
    },
    {
      title: 'Ativo',
      field: 'ativo',
      hidden: false,
      searchable: false,
      render: (rowData: ITipoSolicitacao) => (
        <Chip
          className={clsx(classes.active, !rowData.ativo && classes.inactive)}
          variant="outlined"
          size="small"
          label={rowData.ativo ? 'Sim' : 'Não'}
        />
      ),
      cellStyle: {
        width: '10%',
      },
    },
  ];

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }

    getRequestType();
  }, []);

  useEffect(() => {
    async function fetchRequestType() {
      await api
        .get<ITipoSolicitacao>(
          `api/TipoSolicitacao/Get/${utilitySelector.dialogId}`
        )
        .then(result => {
          reset(result.data);
          setIsFetch(false);
        })
        .catch(() => {
          setIsFetch(false);
        });
    }

    if (utilitySelector.dialogOpen) {
      if (utilitySelector.dialogId) {
        setIsFetch(true);
        fetchRequestType();
      }
    }
  }, [utilitySelector.dialogOpen]);

  const getRequestType = async () => {
    setIsLoading(true);

    await api
      .get('/api/TipoSolicitacao/GetAll')
      .then(result => {
        setData(result.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const createRequestType = async (tipoSol: ITipoSolicitacao) => {
    await api
      .post<ITipoSolicitacao>(`api/TipoSolicitacao/Create`, tipoSol)
      .then(result => {
        setData([...data, result.data]);
      });
  };

  const updateRequestType = async (tipoSol: ITipoSolicitacao) => {
    await api
      .put<ITipoSolicitacao>(`api/TipoSolicitacao/Edit`, tipoSol)
      .then(result => {
        const updateTable = [...data];
        const index = updateTable.findIndex(x => x.id === tipoSol.id);
        updateTable[index] = result.data;
        setData(updateTable);
      });
  };

  const activeRequestType = async (tipoSol: ITipoSolicitacao) => {
    tipoSol.ativo = !tipoSol.ativo;
    await updateRequestType(tipoSol);
  };

  const onSubmit = async (formData: ITipoSolicitacao) => {
    if (utilitySelector.dialogId) {
      await updateRequestType(formData);
    } else {
      await createRequestType(formData);
    }

    dispatch(closeDialog());
    reset({});
  };

  const handleClose = () => {
    dispatch(closeDialog());
    reset({});
  };

  return (
    <Page>
      <MaterialTableX
        title={<h2>Tipos de Solicitações ({data.length})</h2>}
        columns={columns}
        data={data}
        isLoading={isLoading}
        onRefresh={getRequestType}
        onActive={activeRequestType}
      />
      <DialogForm
        titleDiag="Tipo de Solicitação"
        subTitle="Informações do Tipo de Solicitação"
        open={utilitySelector.dialogOpen}
        isLoading={isFetch}
        handleClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          name="descricao"
          control={control}
          defaultValue=""
          variant="standard"
          fullWidth
          label="Descrição"
          autoFocus
          InputLabelProps={{ shrink: true }}
        />

        <FormControlLabel
          className={classes.formControlChecked}
          control={
            <Controller
              name="ativo"
              control={control}
              defaultValue
              render={({ field }) => (
                <Checkbox
                  {...field}
                  color="primary"
                  onChange={e => field.onChange(e.target.checked)}
                  checked={field.value || false}
                />
              )}
            />
          }
          label="Ativo"
        />
      </DialogForm>
    </Page>
  );
};

export default RequestType;
