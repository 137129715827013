import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import {
  Brightness1,
  FilterTiltShift,
  Help,
  ThumbUp,
  Autorenew,
  FormatListBulleted,
  Timer,
  Cancel,
  CheckCircle,
  Warning,
} from '@material-ui/icons';
import { format, toDate } from 'date-fns-tz';
import { addDays } from 'date-fns';
import api from '../../services/api';
import {
  IDashboardFilial,
  IDashboardPrioridade,
  IDashboardSituacoes,
  IFilters,
  ISolicitacao,
  ISolicitacaoHistorico,
} from '../../models';
import Page from '../../components/Page';
import Dialog from '../../components/Dialog';
import MaterialTableX from '../../components/MaterialTable';
import CardSituation from './CardSituation';
import TasksPriority from './TasksPriority';
import TasksBranch from './TasksBranch';
import Efficiency from './Efficiency';
import CardWarning from './CardWarning';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { userSelector } = useLogin();
  const [data, setData] = useState<ISolicitacao[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [dashBranch, setDashBranch] = useState<IDashboardFilial[]>([]);
  const [dashPrior, setDashPrior] = useState<IDashboardPrioridade[]>([]);
  const [dashSit, setDashSit] = useState<IDashboardSituacoes>({
    pendentes: 0,
    aguardando: 0,
    andamento: 0,
    aprovadas: 0,
    canceladas: 0,
    solicitacoesCancelamento: 0,
    solicitacoesAprovacao: 0,
    finalizadas: 0,
  });
  const [dashEfficiency, setDashEfficiency] = useState(0);
  const [dashLate, setDashLate] = useState(0);
  const [solicitacao, setSolicitacao] = useState<ISolicitacao>();
  const [openHistory, setOpenHistory] = useState(false);
  const [requestHistory, setRequestHistory] = useState<ISolicitacaoHistorico[]>(
    []
  );

  useEffect(() => {
    if (userSelector.id !== '') {
      if (!localStorage.getItem('token')) {
        history.push('/');
      }

      getDashboard();
    }
  }, [userSelector.id]);

  const columns = [
    {
      title: '',
      field: 'id',
      hidden: false,
      render: (rowData: ISolicitacao) =>
        returnColorPriority(rowData.prioridade),
      cellStyle: {
        width: '0.1%',
      },
    },
    {
      title: 'Código',
      field: 'codigo',
      hidden: false,
      cellStyle: {
        width: '5%',
      },
    },
    {
      title: 'Descrição',
      field: 'descricao',
      hidden: false,
      cellStyle: {
        width: '31.99%',
      },
    },
    {
      title: 'Data Emissão',
      field: 'dataInclusao',
      hidden: false,
      sorting: false,
      render: (rowData: ISolicitacao) => (
        <div>
          {rowData.dataInclusao &&
            format(toDate(rowData.dataInclusao), 'dd/MM/yyyy')}
        </div>
      ),
      cellStyle: {
        width: '15%',
      },
    },
    {
      title: 'Previsão',
      field: 'dataPrevisao',
      hidden: false,
      render: (rowData: ISolicitacao) => (
        <div>
          {rowData.dataPrevisao &&
            format(toDate(rowData.dataPrevisao), 'dd/MM/yyyy')}
        </div>
      ),
      cellStyle: {
        width: '8%',
      },
    },
    {
      title: 'Departamento',
      field: 'descricaoDepartamento',
      hidden: false,
      cellStyle: {
        width: '15%',
      },
    },
    {
      title: 'Usuário',
      field: 'nomeUsuario',
      hidden: false,
      cellStyle: {
        width: '15%',
      },
    },
    {
      title: 'Situação',
      field: 'descricaoSituacao',
      hidden: false,
      render: (rowData: ISolicitacao) => (
        <div>
          {rowData.descricaoSituacao}
          <IconButton
            onClick={() => {
              setIsLoadingHistory(true);
              try {
                setSolicitacao(rowData);
                getRequestsHistory(rowData.id);
                setOpenHistory(true);
              } finally {
                setIsLoadingHistory(false);
              }
            }}
            size="small"
          >
            <Tooltip title="Visualizar Histórico" placement="bottom">
              <FormatListBulleted fontSize="small" />
            </Tooltip>
          </IconButton>
        </div>
      ),
      cellStyle: {
        width: '10%',
      },
    },
  ];

  const columnsHistory = [
    {
      title: 'Data inclusão',
      field: 'dataInclusao',
      hidden: false,
      cellStyle: {
        width: '10%',
      },
      render: (rowData: ISolicitacao) => (
        <div>{format(toDate(rowData.dataInclusao), 'dd/MM/yyyy HH:mm:ss')}</div>
      ),
    },
    {
      title: 'Observação',
      field: 'observacao',
      hidden: false,
      cellStyle: {
        width: '80%',
      },
    },
    {
      title: 'Situação',
      field: 'descricaoSituacao',
      hidden: false,
      cellStyle: {
        width: '10%',
      },
    },
  ];

  const actionsX: any[] = [];

  const getRequests = async () => {
    setIsLoadingRequest(true);

    await api
      .get(
        `/api/Solicitacao/DashboardLastInteractions/?usuario=${userSelector.id}&tipoUs=${userSelector.tipo}`
      )
      .then(result => {
        setData(result.data);
        setIsLoadingRequest(false);
      })
      .catch(() => {
        setIsLoadingRequest(false);
      });
  };

  const getDashboard = async () => {
    setIsLoading(true);
    try {
      await getDashboardSituation();
      await getDashboardBranch();
      await getDashboardPriority();

      if (userSelector.tipo !== '2') {
        await getDashboardEfficiency();
      }

      await getDashboardLate();
      await getRequests();
    } finally {
      setIsLoading(false);
    }
  };

  const getDashboardBranch = async () => {
    await api
      .get<IDashboardFilial[]>(
        `/api/Solicitacao/DashboardBranch/?usuario=${userSelector.id}&tipoUs=${userSelector.tipo}`
      )
      .then(result => {
        setDashBranch(result.data);
      });
  };

  const getDashboardPriority = async () => {
    await api
      .get<IDashboardPrioridade[]>(
        `/api/Solicitacao/DashboardPriority/?usuario=${userSelector.id}&tipoUs=${userSelector.tipo}`
      )
      .then(result => {
        setDashPrior(result.data);
      });
  };

  const getDashboardSituation = async () => {
    await api
      .get<IDashboardSituacoes>(
        `/api/Solicitacao/DashboardSituations/?usuario=${userSelector.id}&tipoUs=${userSelector.tipo}`
      )
      .then(result => {
        setDashSit(result.data);
      });
  };

  const getDashboardEfficiency = async () => {
    await api
      .get<number>(
        `/api/Solicitacao/DashboardEfficiency/?usuario=${userSelector.id}&tipoUs=${userSelector.tipo}`
      )
      .then(result => {
        setDashEfficiency(result.data);
      });
  };

  const getDashboardLate = async () => {
    await api
      .get<number>(
        `/api/Solicitacao/DashboardLate/?usuario=${userSelector.id}&tipoUs=${userSelector.tipo}`
      )
      .then(result => {
        setDashLate(result.data);
      });
  };

  const getRequestsHistory = async (id: string) => {
    setIsLoadingHistory(true);

    await api
      .get<ISolicitacaoHistorico[]>(
        `/api/SolicitacaoHistorico/GetAll/${id}/?ordemDesc=${true}`
      )
      .then(result => {
        setRequestHistory(result.data);
        setIsLoadingHistory(false);
      })
      .catch(() => {
        setIsLoadingHistory(false);
      });
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
    setRequestHistory([]);
  };

  const onView = (id: string) => {
    history.push(`/solicitacoes/${id}`);
  };

  const returnColorPriority = (prioridade: string) => {
    if (prioridade === '3') {
      return (
        <Tooltip title="Prioridade baixa" placement="top-start">
          <Brightness1 style={{ color: '#006600', fontSize: 14 }} />
        </Tooltip>
      );
    }

    if (prioridade === '2') {
      return (
        <Tooltip title="Prioridade normal" placement="top-start">
          <Brightness1 style={{ color: '#e6e600', fontSize: 14 }} />
        </Tooltip>
      );
    }

    if (prioridade === '1') {
      return (
        <Tooltip title="Prioridade alta" placement="top-start">
          <Brightness1 style={{ color: '#990000', fontSize: 14 }} />
        </Tooltip>
      );
    }

    return <Brightness1 style={{ color: '#000000', fontSize: 14 }} />;
  };

  const clickCard = (id: string, description: string, late: boolean) => {
    let dateIn = null;
    let dateFim = null;

    if (id === 'R' || id === 'C' || id === 'F') {
      const today = new Date();

      dateIn = addDays(today, -30);
      dateFim = today;
    }

    let obj: IFilters;

    if (late) {
      obj = {
        situation: null,
        priority: null,
        branch: null,
        requestType: null,
        dateInclusionInitial: null,
        dateInclusionFinal: null,
        dateForecastInitial: null,
        dateForecastFinal: new Date(),
        departamentId: undefined,
        myRequest: false,
      };
    } else {
      obj = {
        situation: {
          id,
          description,
        },
        priority: null,
        branch: null,
        requestType: null,
        dateInclusionInitial: dateIn,
        dateInclusionFinal: dateFim,
        dateForecastInitial: null,
        dateForecastFinal: null,
        departamentId: undefined,
        myRequest: false,
      };
    }

    localStorage.setItem('filterRequest', JSON.stringify(obj));
    history.push('/solicitacoes');
  };

  const verifyWarning = () => {
    return (
      dashLate > 0 ||
      dashSit.solicitacoesCancelamento > 0 ||
      dashSit.solicitacoesAprovacao > 0
    );
  };

  return (
    <Page>
      <Grid container spacing={3}>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <CardSituation
            title="Pendentes"
            countSituations={dashSit.pendentes}
            descPeriod="Registros em Geral"
            color="#808080"
            click={() => clickCard('P', 'Pendente', false)}
            imageInfo={<FilterTiltShift color="inherit" fontSize="large" />}
          />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <CardSituation
            title="Aprovadas"
            countSituations={dashSit.aprovadas}
            descPeriod="Últimos 30 dias"
            color="#4d004d"
            click={() => clickCard('R', 'Aprovada', false)}
            imageInfo={<ThumbUp color="inherit" fontSize="large" />}
          />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <CardSituation
            title="Andamento"
            countSituations={dashSit.andamento}
            descPeriod="Registros em Geral"
            color="#000066"
            click={() => clickCard('E', 'Em Andamento', false)}
            imageInfo={<Autorenew color="inherit" fontSize="large" />}
          />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <CardSituation
            title="Aguardando"
            countSituations={dashSit.aguardando}
            descPeriod="Registros em Geral"
            color="#527a7a"
            click={() => clickCard('A', 'Aguardando', false)}
            imageInfo={<Timer color="inherit" fontSize="large" />}
          />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <CardSituation
            title="Canceladas"
            countSituations={dashSit.canceladas}
            descPeriod="Últimos 30 dias"
            color="#990000"
            click={() => clickCard('C', 'Cancelada', false)}
            imageInfo={<Cancel color="inherit" fontSize="large" />}
          />
        </Grid>
        <Grid item lg={2} sm={6} xl={2} xs={12}>
          <CardSituation
            title="Finalizadas"
            countSituations={dashSit.finalizadas}
            descPeriod="Últimos 30 dias"
            color="#006600"
            click={() => clickCard('F', 'Finalizada', false)}
            imageInfo={<CheckCircle color="inherit" fontSize="large" />}
          />
        </Grid>
        <Grid
          item
          lg={4}
          md={userSelector.tipo === '2' ? 12 : 6}
          xl={4}
          xs={12}
        >
          <TasksPriority data={dashPrior} />
        </Grid>

        {verifyWarning() ? (
          <Grid
            item
            lg={userSelector.tipo === '2' ? 6 : 4}
            md={userSelector.tipo === '2' ? 8 : 6}
            xl={userSelector.tipo === '2' ? 6 : 4}
            xs={12}
          >
            <TasksBranch data={dashBranch} />
          </Grid>
        ) : (
          <Grid
            item
            lg={userSelector.tipo === '2' ? 8 : 6}
            md={userSelector.tipo === '2' ? 12 : 6}
            xl={userSelector.tipo === '2' ? 8 : 6}
            xs={12}
          >
            <TasksBranch data={dashBranch} />
          </Grid>
        )}

        {userSelector.tipo !== '2' && (
          <Grid item lg={2} md={verifyWarning() ? 6 : 12} xl={2} xs={12}>
            <Efficiency value={dashEfficiency} />
          </Grid>
        )}

        {verifyWarning() && (
          <Grid
            item
            lg={2}
            md={userSelector.tipo === '2' ? 4 : 6}
            xl={2}
            xs={12}
          >
            <Grid container spacing={1}>
              {dashLate > 0 && (
                <Grid item md={12} xs={12}>
                  <CardWarning
                    title="Atrasadas"
                    value={dashLate}
                    icon={
                      <Warning
                        style={{
                          fontSize:
                            // prettier-ignore
                            dashSit.solicitacoesCancelamento > 0 ||
                              dashSit.solicitacoesAprovacao > 0 ? 40 : 80,
                          color: '#e6e600',
                        }}
                      />
                    }
                    resize={
                      dashSit.solicitacoesCancelamento > 0 ||
                      dashSit.solicitacoesAprovacao > 0
                    }
                    click={() => clickCard('', '', true)}
                  />
                </Grid>
              )}
              {dashSit.solicitacoesCancelamento > 0 &&
                dashSit.solicitacoesAprovacao <= 0 && (
                  <Grid item md={12} xs={12}>
                    <CardWarning
                      title="Solicitações Canc."
                      value={dashSit.solicitacoesCancelamento}
                      icon={
                        <Help
                          style={{
                            fontSize: dashLate > 0 ? 40 : 80,
                            color: '#990000',
                          }}
                        />
                      }
                      resize={dashLate > 0}
                      click={() =>
                        clickCard('S', 'Solicitação de Cancelamento', false)
                      }
                    />
                  </Grid>
                )}
              {dashSit.solicitacoesAprovacao > 0 &&
                dashSit.solicitacoesCancelamento <= 0 && (
                  <Grid item md={12} xs={12}>
                    <CardWarning
                      title="Solicitações Aprov."
                      value={dashSit.solicitacoesAprovacao}
                      icon={
                        <Help
                          style={{
                            fontSize: dashLate > 0 ? 40 : 80,
                            color: 'primary',
                          }}
                        />
                      }
                      resize={dashLate > 0}
                      click={() =>
                        clickCard('L', 'Solicitação de Aprovação', false)
                      }
                    />
                  </Grid>
                )}
              {dashSit.solicitacoesCancelamento > 0 &&
                dashSit.solicitacoesAprovacao > 0 && (
                  <>
                    <Grid item md={6} xs={12}>
                      <CardWarning
                        title="Aprov."
                        value={dashSit.solicitacoesAprovacao}
                        icon={
                          <Help
                            style={{
                              fontSize: 40,
                              color: 'primary',
                            }}
                          />
                        }
                        resize
                        click={() =>
                          clickCard('L', 'Solicitação de Aprovação', false)
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <CardWarning
                        title="Canc."
                        value={dashSit.solicitacoesCancelamento}
                        icon={
                          <Help
                            style={{
                              fontSize: 40,
                              color: '#990000',
                            }}
                          />
                        }
                        resize
                        click={() =>
                          clickCard('S', 'Solicitação de Cancelamento', false)
                        }
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <MaterialTableX
        title={<h2>Últimas Interações</h2>}
        columns={columns}
        data={data}
        style={{ marginTop: 10 }}
        isLoading={isLoadingRequest}
        onRefresh={getRequests}
        onView={x => onView(x)}
        hideActionAdd
        hideActionEdit
        hideActionDelete
      />

      <Dialog
        titleDiag={`Solicitação: ${solicitacao?.codigo} - ${solicitacao?.descricao}`}
        open={openHistory}
        captionCancel="Fechar"
        maxWidth="xl"
        handleClose={handleCloseHistory}
      >
        <MaterialTableX
          title="Histórico"
          actionsOverride={actionsX}
          columns={columnsHistory}
          data={requestHistory}
          isLoading={isLoadingHistory}
          style={{ marginTop: 20 }}
          hideColumnsButton
          hideExportButton
        />
      </Dialog>
    </Page>
  );
};

export default Dashboard;
