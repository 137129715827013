import React from 'react';
import { ThemeProvider, createTheme, Collapse } from '@material-ui/core';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Routes from './routes';
import GlobalStyle from './styles/global';
import store from './store/store';
import CloseButton from './components/CloseButton';
import { SnackbarUtilsConfigurator } from './components/SnackbarUtilsConfigurator';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#000066',
      },
      secondary: {
        main: '#b3b3b3',
      },
    },
    overrides: {
      MuiTableCell: {
        footer: {
          left: 0,
          bottom: 0, // <-- KEY
          zIndex: 2,
          position: 'sticky',
        },
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          action={key => <CloseButton keySnack={key} />}
          TransitionComponent={Collapse}
          preventDuplicate
        >
          <SnackbarUtilsConfigurator />
          <GlobalStyle />
          <Routes />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
