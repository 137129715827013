import React from 'react';
import {
  Button,
  Box,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Box textAlign="center">
            <LockOutlinedIcon style={{ fontSize: 100 }} />
          </Box>
          <Typography align="center" color="textPrimary" variant="h4">
            404: A página que você está procurando não está aqui
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Ou você tentou um caminho obscuro ou veio aqui por engano. Seja o
            que for, tente usar a navegação
          </Typography>

          <Box textAlign="center" style={{ marginTop: 20 }}>
            <Button
              style={{
                textTransform: 'none',
                width: 200,
                height: 50,
                fontSize: 18,
              }}
              variant="contained"
              color="primary"
              component={Link}
              to="/dashboard"
            >
              Ir para o início
            </Button>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default NotFound;
