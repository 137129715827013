import { makeStyles } from '@material-ui/core/styles';
import { ThemeContext } from 'styled-components';

export default makeStyles(theme => ({
  inactive: {
    color: '#5e5e6e',
    borderColor: '#F0F0F5',
    backgroundColor: '#F0F0F5',
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  cardPaper: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  autoComplete: {
    marginBottom: 20,
  },
  autoCompleteTop: {
    marginTop: 20,
    marginBottom: 20,
  },
  filters: {
    padding: theme.spacing(3),
  },
  icon: {
    color: '#5e5e6e',
  },
  grid: {
    backgroundColor: '#FFFFFF',
    padding: 10,
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  formControlChecked: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  dropZone: {
    minHeight: 20,
  },
  dropZoneIcon: {
    width: 17,
    height: 17,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  paragraph: {
    fontSize: 14,
  },
  dropZoneContainer: {
    maxWidth: 600,
    padding: 30,
  },
  inheritedLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  errorLabel: {
    color: '#f44336',
  },
  error: {
    color: '#f44336',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    marginTop: 3,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  progress: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
