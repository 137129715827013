import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    textAlign: 'center',
    pointerEvents: 'none',
  },
}));

type IParams = CardProps & {
  title: string;
  value: number;
  resize: boolean;
  icon: React.ReactNode;
  click: () => void;
};

const CardWarning = ({
  title,
  value,
  resize,
  icon,
  click,
  ...rest
}: IParams) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} onClick={click} {...rest}>
      <CardHeader title={title} titleTypographyProps={{ variant: 'body1' }} />
      <Divider />
      <CardContent>
        <Box height={resize ? 66.5 : 238} position="relative">
          <div className={classes.overlay}>
            {icon}
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: resize ? 20 : 40,
              }}
            >
              {value}
            </span>
          </div>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardWarning;
