import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Drawer, Button as ButtonUI } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import useUtility from '../../hooks/useUtility';
import Button from '../Button';
import styles from './styles';

interface Props {
  className?: string;
  onClear(): void;
  onSubmit: () => Promise<void>;
}

const Filter: React.FC<Props> = ({
  className,
  onClear,
  onSubmit,
  children,
  ...rest
}) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { utilitySelector, closeFilter } = useUtility();

  const handleDrawerClose = () => {
    dispatch(closeFilter());
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={handleDrawerClose}
      open={utilitySelector.filterOpen}
      variant="temporary"
    >
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={onSubmit}
      >
        <div className={classes.header}>
          <ButtonUI
            className={classes.button}
            onClick={handleDrawerClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Fechar
          </ButtonUI>
        </div>
        {children}
        <div className={classes.actions}>
          <ButtonUI
            className={classes.button}
            fullWidth
            onClick={onClear}
            variant="contained"
          >
            <DeleteIcon className={classes.buttonIcon} />
            Limpar
          </ButtonUI>
          <Button caption=" Aplicar Filtros" fullWidth type="submit" />
        </div>
      </form>
    </Drawer>
  );
};

export default Filter;
