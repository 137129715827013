import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  ListItemIcon,
  Hidden,
} from '@material-ui/core';
import { AccountCircle, Settings, ExitToApp } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import AvatarBadge from '../AvatarBadge';
import useLogin from '../../hooks/useLogin';
import getInitials from '../../utils/getInitials';
import getFirstName from '../../utils/getFirstName';
import styles from './styles';

const AvatarMenu: React.FC = () => {
  const classes = styles();

  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { userSelector, logout } = useLogin();
  const anchorRef = useRef<null | HTMLElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseLogout = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    dispatch(logout());
    setOpen(false);
    history.push('/');
  };

  const handleAccount = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    history.push('/perfil');
  };

  return (
    <>
      <ListItem
        button
        innerRef={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        alignItems="flex-start"
        className={classes.paddingRightZero}
      >
        <ListItemAvatar>
          <AvatarBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            {userSelector.urlAvatar ? (
              <Avatar alt={userSelector.nome} src={userSelector.urlAvatar} />
            ) : (
              <Avatar>{getInitials(userSelector.nome)}</Avatar>
            )}
          </AvatarBadge>
        </ListItemAvatar>
        <Hidden implementation="css" smDown>
          <ListItemText
            primary={
              <>
                <Typography component="span" variant="subtitle2">
                  {getFirstName(userSelector.nome)}
                </Typography>
              </>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="caption"
                  className={classes.inline}
                >
                  {userSelector.descricaoTipo}
                </Typography>
              </>
            }
          />
        </Hidden>
      </ListItem>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <MenuItem onClick={handleAccount}>
                    <ListItemIcon className={classes.menuIcon}>
                      <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    Perfil
                  </MenuItem>
                  <MenuItem onClick={handleClose} disabled>
                    <ListItemIcon className={classes.menuIcon}>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Configurações
                  </MenuItem>
                  <MenuItem onClick={handleCloseLogout}>
                    <ListItemIcon className={classes.menuIcon}>
                      <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    Sair
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AvatarMenu;
