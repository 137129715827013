import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  active: {
    color: '#ffffff',
    borderColor: '#009900',
    backgroundColor: '#009900',
  },
  inactive: {
    color: '#ffffff',
    borderColor: '#ff1a1a',
    backgroundColor: '#ff1a1a',
  },
  chip: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    margin: '10px',
  },
  formControlChecked: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  divDocument: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  grid: {
    backgroundColor: '#FFFFFF',
    padding: 10,
  },
}));
