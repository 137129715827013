import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  inline: {
    display: 'inline',
    color: '#fff',
  },
  menuIcon: {
    minWidth: '33px',
  },
  paddingRightZero: {
    paddingRight: 0,
  },
}));
