import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Container, Grid } from '@material-ui/core';
import Page from '../../components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';

const Account = () => {
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/');
    }
  }, []);

  return (
    <Page title="Conta">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
