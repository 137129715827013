import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { Controller, UseControllerProps } from 'react-hook-form';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { TextFieldStyled } from './styles';

const PasswordField: React.FC<TextFieldProps & UseControllerProps<any>> = ({
  name,
  control,
  rules,
  defaultValue,
  ...textFieldProps
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextFieldStyled
          {...field}
          {...textFieldProps}
          type={show ? 'text' : 'password'}
          margin="normal"
          InputProps={{
            ...textFieldProps.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  focusRipple={false}
                  aria-label="Visualizar senha"
                  onClick={() => setShow(!show)}
                >
                  {show ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={field.onChange}
          value={field.value || ''}
          error={!!error}
          helperText={error ? error.message : null}
          ref={field.ref}
        />
      )}
    />
  );
};

export default PasswordField;
