import React, { useEffect, useState } from 'react';
import { CircularProgress, CssBaseline } from '@material-ui/core';
import AppMenu from '../AppMenu';
import useLogin from '../../hooks/useLogin';
import styles from './styles';

interface Props {
  title?: string;
}

const Page: React.FC<Props> = ({ title, children }) => {
  const classes = styles();
  const { userSelector, onGetUserInfo } = useLogin();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refreshLogin();

    async function refreshLogin() {
      await onGetUserInfo();
    }
  }, []);

  useEffect(() => {
    if (userSelector.id !== '' && isLoading) {
      setIsLoading(false);
    }
  }, [userSelector.id]);

  return (
    <div>
      <CssBaseline />
      <AppMenu>
        {isLoading ? (
          <div className={classes.progress}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            {title && <h2 className={classes.title}>{title}</h2>}
            {children}
          </>
        )}
      </AppMenu>
    </div>
  );
};

export default Page;
